import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { link } from 'fs';
import { AuthenticationService } from 'app/_services/authentication.service';
import { AlertesService } from 'app/_services/alertes.service';

import { Router } from '@angular/router';
 
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = true;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    // {
    //   value: 'cosmic',
    //   name: 'Cosmic',
    // },
    // {
    //   value: 'corporate',
    //   name: 'Corporate',
    // },
  ];

  currentTheme = 'dark';

  userMenu = [ { title: 'Mon Compte' },
  // { title: 'Accueil' },
  // { title: 'Alert' },
  // { title: 'Notification' },
  // { title: 'Administration' },
   { title: 'AGRO-START' , url : 'http://10.10.100.205:4200'},
    { title: 'Log out'} ];

  

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private alerteService: AlertesService,
              private router: Router,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private serviceAuth: AuthenticationService) {
  }

  
dataAlerte_a_affiche = [];

  ngOnInit() {
    this.menuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'my-context-menu'),
      map(({ item: { title } }) => title),
    )
    .subscribe(title => this.serviceAuth.logout());
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);


this.menuService.onItemClick().subscribe((event) => {

if(event.item.title === 'Log out'){
  localStorage.removeItem('x-access-token');
  window.location.reload();
 //alert();
}
});







this.dataAlerte_a_affiche = [];
this.alerteService.getAlertes().subscribe((item) => {
  item.forEach(x => {
   // element.forEach(x => {
      
           if (!x.seen) {
            this.dataAlerte_a_affiche.push(x)
            // console.log(x)
           }
         
   // })
  })
})





  }
  goto(){this.router.navigateByUrl('/pages/alertes');}
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  
}
